import * as React from "react"
import Layout from "../components/layout"
import Home from "../components/home"
import { graphql } from 'gatsby'

// markup
const IndexPage = ({ data: { allInstaNode, file } }) => {
  return (
    <Layout>
      <title>Tête à Clak, salon de coiffure à Vevey</title>
      <Home nodes={allInstaNode} jumbo={file} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    allInstaNode(sort: {fields: timestamp, order: DESC}, limit: 6) {
      edges {
        node {
          id
          caption
          localFile {
            childImageSharp {
              fluid(quality: 85, maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    },
    file(relativePath: { eq: "createur_capillaire.jpg" }) {
      childImageSharp {
        # Specify a fixed image and fragment.
        # The default width is 400 pixels
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
