import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import {Container, Grid, GridWrap, Tableau} from '../styles/global';
import Helmet from "react-helmet"


const InstaImg = styled.div`
    padding: 7px 10px;
`
const CtaInsta = styled.p`
    text-align:center;
    a {
        color: #000;  
    }
    a:visited {
        opacity:0.9;
        color: #000;
    }
`

const JumboImg = styled(Img)`
    margin-top: 40px;
`

const Nodea = ({ node }) => (
    <div className="grid-12 col"><InstaImg><a href={'https://www.instagram.com/p/'+node.id} target="_blank" rel="noopener noreferrer" title={((node.caption.length > 48) ? node.caption.substr(0, 48-1) + '…' : node.caption)}><Img fluid={node.localFile.childImageSharp.fluid} /></a></InstaImg></div>
  )

  export default function Home({nodes, jumbo}) {

    return (
    <Container>
        <Helmet title="Tête à clak – Salon de coiffure à Vevey">
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <meta name="title" content="Tête à clak – Salon de coiffure à Vevey" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <link rel="manifest" href="/site.webmanifest"/>
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000"/>
          <meta name="msapplication-TileColor" content="#ffffff"/>
          <meta name="theme-color" content="#ffffff"></meta>
          <meta name="description" content="A Vevey, coiffeur femme, homme et enfant. Barbier. Offrez à vos cheveux une parenthèse de bien-être." />
            
          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.tete-a-clak.ch/" />
          <meta property="og:title" content="Tête à clak – Salon de coiffure à Vevey" />
          <meta property="og:description" content="A Vevey, coiffeur femme, homme et enfant. Barbier. Offrez à vos cheveux une parenthèse de bien-être." />
          <meta property="og:image" content="https://www.tete-a-clak.ch/so_net.png" />
          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.tete-a-clak.ch/" />
          <meta property="twitter:title" content="Tête à clak – Salon de coiffure à Vevey" />
          <meta property="twitter:description" content="A Vevey, coiffeur femme, homme et enfant. Barbier. Offrez à vos cheveux une parenthèse de bien-être." />
          <meta property="twitter:image" content="https://www.tete-a-clak.ch/so_net.png" />
          <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Tête à Clak",
            "image": "https://www.tete-a-clak.ch/so_net.png",
            "@id": "",
            "url": "https://www.tete-a-clak.ch",
            "telephone": "+41219211779",
            "address":
            [
                {
                    "@type": "PostalAddress",
                    "streetAddress": "Rue du Centre 8",
                    "addressLocality": "Vevey",
                    "postalCode": "1800",
                    "addressCountry": "CH",
                    "addressRegion": "VD"
                }
            ],
            "sameAs": [
                "https://www.facebook.com/teteaclak.createur.capillaire",
                "https://www.instagram.com/teteaclak/"
            ]
            })}
        </script>
        </Helmet>
        <Grid>
            <div className={'grid-23 col'}><JumboImg fluid={jumbo.childImageSharp.fluid} /></div>
            <Tableau className={'grid-13 col'}>
                <GridWrap>
                {nodes.edges.map(instagram => (
                    <Nodea key={instagram.node.id} node={instagram.node} />
                ))}
                </GridWrap>
                <CtaInsta><a href="https://www.instagram.com/teteaclak/" rel="me">Suivez-nous sur instagram</a> pour<br />
                &gt; des inspirations hebdomadaires<br />
                &gt; des news produits<br />
                &gt; nos coups de coeur</CtaInsta>
            </Tableau>
        </Grid>
    </Container>
  )
}